import React from "react";
import { Router } from "@reach/router";
import WorkPage from "../components/WorkPage";
import NotFoundPage from "./404";

const workPageRouter = (props) => (
  <Router>
    <WorkPage {...props} path="/work" />
    <NotFoundPage default />
  </Router>
);

export default workPageRouter;
