import React, { useEffect } from "react";
import { camelCase, paramCase } from "change-case";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import WorkContainer from "../../containers/WorkContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import HorizontalCardHalfImage from "../../sections/HorizontalCardHalfImage";
import SimpleImageSlider from "../../sections/SimpleImageSlider";
import PointsColumns from "../../sections/PointsColumns";
import Testimonials from "../../sections/Testimonials";
import scrollToSection from "../../utilities/scroll-to";
import CardsTiles from "../../sections/CardsTiles";
import StickyScrollToNavbar from "../StickyScollToNavbar";

const WorkPage = ({ location }) => {
  const { hash } = location;
  const scrollToTargetSections = ["Industries", "Featured work", "Clientele"];
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        scrollToSection(hash.slice(1));
      }
    }, 1000);
  }, [hash]);

  return (
    <WorkContainer>
      {(workData, aboutData, clientsData, industriesListData) => {
        const pageName = "work";
        const workServicesContent = workData
          .map((ele) => (ele.id === pageName ? ele : null))
          .find((el) => el);
        const {
          pageId,
          pageBanner,
          ourIndustries,
          introduction,
          services,
          ourApproach: workOurApproach,
          ourClients,
        } = workServicesContent;
        const allServices = services
          .filter((service) => !service.hideService)
          .map((service) => ({
            ...service,
            actionButtons: service?.actionButtons?.map((btn) => ({
              ...btn,
              buttonPath: `${btn.buttonPath}/?subject=${camelCase(
                service.serviceName
              )}`,
            })),
          }));

        const isServices = allServices && allServices.length > 0;

        // industriesListData
        const isOurIndustries =
          ourIndustries &&
          !ourIndustries.hideSection &&
          industriesListData &&
          industriesListData.length > 0;

        // aboutContent
        const aboutContent = aboutData.find((data) => data.id === "about");
        const { ourApproach: defaultOurApproach } = aboutContent;

        // clientsContent
        const clientsContent = clientsData.find(
          (data) => data.id === "clients"
        );
        const { textContent: defaultContent, clients } = clientsContent;

        const showOurApproach =
          workOurApproach &&
          !workOurApproach.hideSection &&
          defaultOurApproach?.approaches?.length > 0;
        const ourApproachContent =
          (showOurApproach &&
            (workOurApproach.textContent.heading ||
              workOurApproach.textContent.content) &&
            workOurApproach.textContent) ||
          defaultOurApproach?.textContent;

        const isOurClients = ourClients && !ourClients.hideSection;
        const clientContent =
          (isOurClients &&
            (ourClients.textContent.heading ||
              ourClients.textContent.content) &&
            ourClients.textContent) ||
          defaultContent;

        return (
          <>
            {pageBanner && !pageBanner.hideSection && (
              <PageBanner
                bannerContent={pageBanner.textContent}
                bgImage={pageBanner.backgroundImage}
              />
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro data={introduction} page={pageId} />
            )}
            {isOurIndustries && isServices && isOurClients && (
              <StickyScrollToNavbar
                links={scrollToTargetSections.map((i) => ({
                  label: i,
                }))}
              />
            )}
            {isOurIndustries && (
              <CardsTiles
                data={{
                  actionButtons: ourIndustries.actionButtons,
                  cards: industriesListData
                    .filter((industry) => !industry.hideIndustry)
                    .map((industry) => {
                      const { summary, heroContent, title } = industry;
                      const { actionButtons, content, heading } = summary;
                      const { image } = heroContent;
                      return {
                        actionButtons,
                        content,
                        heading,
                        image,
                        title,
                        to: `/industries/${paramCase(title)}`,
                      };
                    }),
                  textContent: ourIndustries.textContent,
                }}
                hashName={camelCase(scrollToTargetSections[0])}
              />
            )}
            {isServices &&
              allServices.map((service, index) => (
                <Element
                  name={index === 0 ? camelCase(scrollToTargetSections[1]) : ""}
                >
                  <HorizontalCardHalfImage
                    key={`${camelCase(service.serviceName)}-${index}`}
                    data={service}
                    hashName={camelCase(service.serviceName)}
                    isEven={(index + 1) % 2 === 0}
                    page={pageId}
                  />
                  {service?.relativeImages?.length > 0 && (
                    <SimpleImageSlider
                      data={service.relativeImages}
                      page={pageId}
                    />
                  )}
                </Element>
              ))}
            {showOurApproach && (
              <PointsColumns
                data={{
                  ...defaultOurApproach,
                  textContent: ourApproachContent,
                }}
                sectionSx={{ bg: "primarySoft" }}
              />
            )}
            {isOurClients && (
              <Testimonials
                data={{
                  clients,
                  textContent: clientContent,
                }}
                hashName={camelCase(scrollToTargetSections[2])}
              />
            )}
          </>
        );
      }}
    </WorkContainer>
  );
};

WorkPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

WorkPage.defaultProps = {
  location: null,
};

export default WorkPage;
