import React, { useContext } from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import LinkTo from "../../components/LinkTo";
import Section from "../../components/Section";
import ActionButtons from "../../components/ActionButtons";

const CardsTiles = ({
  sectionSx,
  data,
  containerSx,
  contentSx,
  contentWrapperSx,
  cardColSx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Section
      containerSx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: [null, "maxWidths.sm", "maxWidths.md", "maxWidths.lg"],
        ...containerSx,
      }}
      sx={{ bg: "white", mb: 0, mt: 0, ...sectionSx }}
      {...rest}
    >
      {(data?.textContent?.heading || data?.textContent?.content) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...contentWrapperSx,
          }}
        >
          <MarkdownToHtml
            heading={data.textContent.heading}
            source={data.textContent.content}
            sx={{
              ".md_content": {
                flex: "auto",
                width: ["100%", null, null, "50%"],
              },
              ".md_heading": {
                pr: [null, null, null, 6],
                width: ["100%", null, null, "50%"],
              },
              display: "flex",
              flexWrap: "wrap",
              h3: {
                color: "primaryLight",
              },
              ...contentSx,
            }}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", mx: "-1.5rem" }}>
          {data.cards.map((card, index) => {
            const cardTitle = camelCase(card.title);
            return (
              <Box
                key={`${cardTitle}-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: "1.5rem",
                  width: ["100%", null, "calc(100% / 2)", "100%"],
                  ...cardColSx,
                }}
              >
                <LinkTo
                  hoverSx={{
                    // bg: "primarySoft",
                    boxShadow: 2,
                    button: {
                      bg: "primary",
                      color: "white",
                    },
                  }}
                  sx={{
                    bg: "grays.0",
                    boxShadow: 1,
                    button: {
                      bg: "transparent",
                      color: "primary",
                    },
                    display: "flex",
                    flex: "auto",
                    flexDirection: "column",
                    minHeight: "30rem",
                    p: 3,
                    pb: 6,
                    position: "relative",
                    transition: "all .45s",
                    ...card.cardSx,
                  }}
                  to={card.to}
                >
                  {card.image && (
                    <Box
                      sx={{
                        "&:before": {
                          backgroundImage: theme.baseGradients.grays[0],
                          bottom: "0",
                          content: "''",
                          left: "0",
                          position: "absolute",
                          right: "0",
                          top: "0",
                        },
                        backgroundImage: `url('${card.image}')`,
                        backgroundSize: "cover",
                        bottom: "0",
                        left: ["0", null, null, "auto"],
                        position: "absolute",
                        right: "0",
                        top: "0",
                        width: ["100%", null, null, "70%"],
                      }}
                    />
                  )}
                  <MarkdownToHtml
                    content={card.content}
                    heading={card.heading}
                    sx={{
                      color: ["primary", null, null, "text.primary"],
                      fontSize: 3,
                      h2: {
                        color: "primary",
                        fontSize: 4,
                        fontWeight: "medium",
                        mb: 4,
                      },
                      h3: {
                        border: ".4rem solid",
                        borderColor: "primaryLight",
                        borderWidth: "0 0 0 .6rem",
                        color: "primary",
                        display: "none",
                        fontSize: 0,
                        mb: 3,
                        ml: -3,
                        pl: 3,
                        py: 1,
                      },
                      position: "relative",
                      width: ["100%", null, null, "50%"],
                    }}
                  />
                  {card.actionButtons && card.actionButtons.length > 0 && (
                    <ActionButtons
                      buttons={card.actionButtons}
                      buttonSx={{ fontWeight: "medium" }}
                      wrapperSx={{ bottom: 3, position: "absolute" }}
                    />
                  )}
                </LinkTo>
              </Box>
            );
          })}
        </Box>
      </Box>
      {data.actionButtons &&
        data.actionButtons.length > 0 &&
        data.actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
          <ActionButtons
            buttons={data.actionButtons}
            buttonSx={{
              fontWeight: "medium",
            }}
            wrapperSx={{
              mt: 5,
              mx: "auto",
            }}
          />
        )}
    </Section>
  );
};

CardsTiles.propTypes = {
  cardColSx: PropTypes.shape({}),
  containerSx: PropTypes.shape({}),
  contentSx: PropTypes.shape({}),
  contentWrapperSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
        cardSx: PropTypes.shape({}),
        content: PropTypes.string,
        heading: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        to: PropTypes.string,
      })
    ),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

CardsTiles.defaultProps = {
  cardColSx: {},
  containerSx: {},
  contentSx: {},
  contentWrapperSx: {},
  sectionSx: {},
};

export default CardsTiles;
